import {firstValueFrom} from 'rxjs';
import IconBox from '@root/common/IconBox';
import {find, get, map, isNull} from 'lodash';
import {isValidArray} from '@root/libs/utils';
import FormRadio from '@root/common/FormRadio';
import LocaleService from '@root/services/locale';
import BottomSheet from '@root/common/BottomSheet';
import React, {useEffect, useRef, useState} from 'react';
import NextAvailDelService from '@root/services/nextAvailDelivery';
import EventsService, { PICK_LOCATION } from '@root/services/events';

import styles from './styles.module.scss';

function NextDelivery() {
  const sheetRef = useRef(null);
  const {t} = LocaleService.useLocale();
  const [data, setData] = useState(null);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const {locData, locRegion, locCity} = NextAvailDelService.useLocationData();

  const onClickToggle = () => {
    EventsService.trigger({type: PICK_LOCATION});
  }

  if (isNull(locData)) return null;
  return (
    <>
      <div className={styles.next}>
        <a onClick={onClickToggle} className={styles.toggle}>
          <IconBox name="map-marker-outline" className={styles.toggle_icon} />
          <div className={styles.toggle_column}>
            <span className={styles.toggle_region}>{locRegion?.label}</span>
            <span className={styles.toggle_text}>{locCity?.label}</span>
          </div>
          <IconBox name="chevron-down" className={styles.toggle_chev} />
        </a>
        
        <div className={styles.timeslot}>
          <div className={styles.timeslot_day}>
            <IconBox name="truck-fast-outline" className={styles.timeslot_icon} />
            <div className={styles.timeslot_value}>
              <span className={styles.timeslot_text}>{locData?.next?.day} {locData?.next?.date}</span>
              {false && (
                <span className={styles.timeslot_text}>Monday</span>
              )}
            </div>
          </div>
          <span className={styles.timeslot_time}>{locData?.next?.slot}</span>
        </div>
      </div>
    </>
  );
}
export default NextDelivery;
