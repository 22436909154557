import React, { useEffect } from 'react';
import IconBox from '@root/common/IconBox';
import LocaleService from '@root/services/locale';
import NextAvailDelService from '@root/services/nextAvailDelivery';
import EventsService, { PICK_LOCATION } from '@root/services/events';

import styles from './styles.module.scss';

function NextDelivery() {
  const {t} = LocaleService.useLocale();
  const {locData, locRegion, locCity} = NextAvailDelService.useLocationData();

  const onClickToggle = () => {
    EventsService.trigger({type: PICK_LOCATION});
  }

  return (
    <div onClick={onClickToggle} className={styles.next}>
      <a className={styles.next_toggle}>
        <div className={styles.next_location}>
          <span className={styles.next_location_text}>{locCity?.label} - {locRegion?.label}</span>
          <IconBox className={styles.next_location_chev} name='chevron-down' />
        </div>
        <div className={styles.next_timeslot}>
          <div className={styles.next_timeslot_day}>
            <IconBox className={styles.next_timeslot_icon} name='truck-fast-outline' />
            <span className={styles.next_timeslot_daytext}>{locData?.next?.day} {locData?.next?.date}</span>
          </div>
          <span className={styles.next_timeslot_time}>{locData?.next?.slot}</span>
        </div>
      </a>
    </div>
  );
}

export default NextDelivery;
